import { post, get, downFile } from 'common/request'

export default {
//根据ID查询
getById: id => get(`/scEvent/getById?id=${id}`),
//新增
add: args => post('/scEvent/add', args),
//分页查询
list: args => get('/scEvent/list', args),
 //根据ID修改
 update: args => post(`/scEvent/update`,args),
 //根据ID删除
deleteById: id => post(`/scEvent/delete?id=${id}`),
//获取网格树
 getTreeList: args => get('/scGridManagement/getTreeList', args),
  // 获取字典数据
  dictData: args => get('/dict-data/getByDicKind', args),
  //活动记录
  eventRecordList: args => get('/scEventRegistration/eventRecordList', args),
  //活动签到
  sign: args => get('/scEventRegistration/sign', args),
}
