var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ax-table',{ref:"roleTable",attrs:{"show-search":true,"searchForm":_vm.searchForm,"searchActions":_vm.searchActions,"columns":_vm.columns,"dataSourceApi":_vm.api.eventRecordList,"dataSourceParams":_vm.dataSourceParams,"clickable":false,"showToolBar":true,"rowSelection":{
      selectedRowKeys: _vm.selectedRowKeys,
      onChange: _vm.onSelectChange,
    },"dynamicSlotNames":['gridId', 'gridId', 'rating'],"scroll":{ y: '55vh', x: '80vw' }},on:{"reset":_vm.reset,"searchlist":_vm.searchlist},scopedSlots:_vm._u([{key:"isSign",fn:function({ record }){return _c('div',{},[_c('a-button',{class:{
          green: record.isSign === '0',
          grey: record.isSign === '1',
        }},[_vm._v(" "+_vm._s(record.isSign === "0" ? "已签到" : "未签到")+" ")])],1)}},{key:"type",fn:function({ record }){return _c('div',{},[(record.type === '0')?_c('div',[_vm._v("常住人口")]):(record.type === '1')?_c('div',[_vm._v("流入人口")]):_vm._e()])}}])},[_c('div',{staticStyle:{"display":"flex"},attrs:{"slot":"gridId"},slot:"gridId"},[_c('a-cascader',{attrs:{"options":_vm.options,"placeholder":"所属网格","show-search":{ filter: _vm.filter },"change-on-select":"","getPopupContainer":(triggerNode) => triggerNode.parentNode,"z-index":666,"model":_vm.cuurentAreaCode},on:{"change":_vm.onChange},model:{value:(_vm.cuurentAreaCode1),callback:function ($$v) {_vm.cuurentAreaCode1=$$v},expression:"cuurentAreaCode1"}})],1),_c('div',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"slot":"rating"},slot:"rating"},[_c('a-input-number',{attrs:{"placeholder":"请输入","default-value":10,"min":0,"max":10},model:{value:(_vm.coreLeft),callback:function ($$v) {_vm.coreLeft=$$v},expression:"coreLeft"}}),_vm._v("-"),_c('a-input-number',{attrs:{"placeholder":"请输入","default-value":10,"min":0,"max":10},model:{value:(_vm.coreRight),callback:function ($$v) {_vm.coreRight=$$v},expression:"coreRight"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }