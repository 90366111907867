<!--活动记录  -->
<template>
  <div>
    <ax-table
      ref="roleTable"
      :show-search="true"
      :searchForm="searchForm"
      :searchActions="searchActions"
      :columns="columns"
      :dataSourceApi="api.eventRecordList"
      :dataSourceParams="dataSourceParams"
      :clickable="false"
      :showToolBar="true"
      :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :dynamicSlotNames="['gridId', 'gridId', 'rating']"
      @reset="reset"
      @searchlist="searchlist"
      :scroll="{ y: '55vh', x: '80vw' }"
    >
      <!--  :showToolBar="true"  表格列设置 -->
      <div slot="gridId" style="display: flex">
        <a-cascader
          :options="options"
          placeholder="所属网格"
          :show-search="{ filter }"
          change-on-select
          @change="onChange"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :z-index="666"
          :model="cuurentAreaCode"
          v-model="cuurentAreaCode1"
        />
      </div>
      <div slot="isSign" slot-scope="{ record }">
        <a-button
          :class="{
            green: record.isSign === '0',
            grey: record.isSign === '1',
          }"
        >
          {{ record.isSign === "0" ? "已签到" : "未签到" }}
        </a-button>
      </div>
      <div slot="type" slot-scope="{ record }">
        <div v-if="record.type === '0'">常住人口</div>
        <div v-else-if="record.type === '1'">流入人口</div>
      </div>
      <div slot="rating" style="display: flex; align-items: center">
        <a-input-number
          placeholder="请输入"
          :default-value="10"
          :min="0"
          :max="10"
          v-model="coreLeft"
        />-<a-input-number
          placeholder="请输入"
          :default-value="10"
          :min="0"
          :max="10"
          v-model="coreRight"
        />
      </div>
    </ax-table>
  </div>
</template>
    <script>
import api from "./api";

const type = [
  { label: "男", value: "0" },
  { label: "女", value: "1" },
];

const score = [
  { label: "已签到", value: "0" },
  { label: "未签到", value: "1" },
];
const searchForm = [
  {
    label: "姓名",
    type: "inputSearch",
    model: "residentName",
    options: { placeholder: "请输入姓名" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "性别",
    type: "select",
    model: "gender",
    options: { options: type, placeholder: "性别" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属网格",
    type: "select",
    model: "gridId",
    options: { placeholder: "所属网格" },
    formItem: {},
    col: { span: 6 },
  },

  {
    label: "活动评分",
    type: "",
    model: "rating",
    options: { placeholder: "活动评分" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "签到状态",
    type: "select",
    model: "isSign",
    options: { options: score, placeholder: "签到状态" },
    formItem: {},
    col: { span: 6 },
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  data() {
    //这里存放数据
    return {
      api,
      coreLeft: 0,
      coreRight: 0,
      dialogAdd: false,
      cuurentAreaCode: "",
      cuurentAreaCode1: [],
      gridId: "",
      id: this.$route.query.id,
      options: [],
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 120,
      }),
      searchActions: [
        { name: "searchlist", text: "查询" },
        { name: "reset", text: "重置" },
      ],
      columns: this.$ax.tools.initColumn([
        {
          title: "姓名",
          dataIndex: "residentName",
          ellipsis: true,
          width: 80,
        },
        {
          title: "性别",
          dataIndex: "gender",
          ellipsis: true,
          width: 150,
        },
        {
          title: "联系方式",
          dataIndex: "phone",
          ellipsis: true,
          width: 80,
        },
        {
          title: "类型",
          dataIndex: "type",
          ellipsis: false,
          width: 80,
        },
        {
          title: "所属网格",
          dataIndex: "gridName",
          ellipsis: true,
          width: 120,
        },
        {
          title: "活动评分",
          dataIndex: "rating",
          ellipsis: true,
          width: 80,
        },
        {
          title: "评价时间",
          dataIndex: "reveiwdTime",
          ellipsis: true,
          width: 150,
        },

        {
          title: "签到状态",
          dataIndex: "isSign",
          ellipsis: false,
          width: 200,
        },
      ]),
      dataSourceParams: { eventId: this.$route.query.id },
      visible: false,
      // 多选
      selectedRowKeys: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    coreLeft(newVal) {
      if (newVal >= this.coreRight) {
        this.coreRight = newVal + 1; // 确保rightSatisfactionInterval总是大于leftSatisfactionInterval
      }
    },
    coreRight(newVal) {
      if (newVal <= this.coreLeft) {
        this.coreRight = this.coreLeft + 1; // 确保rightSatisfactionInterval总是大于leftSatisfactionInterval
      }
    },
  },
  //方法集合
  methods: {
    filter(inputValue, path) {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    },
    // 获取所属网格数据
    async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.options = options; // 更新选项数据
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    // 级联选择器改变的回调函数
    onChange(value, e) {
      // 获取最后一个ID
      const lastId = value[value.length - 1];
      this.gridId = lastId;
      this.cuurentAreaCode = value[value.length - 1];
      this.cuurentAreaCode1 = value;
    },
    // //重置
    // reset(e) {
    //   this.cuurentAreaCode = "";
    //   this.cuurentAreaCode1 = [];
    //   if (this.$refs.cascader) {
    //     this.$refs.cascader.$refs.input.value = "";
    //   }
    //   this.gridId = ""; // 清空 gridId 参数
    // },

    // // 在搜索方法中根据情况传入或不传入 gridId 参数
    // searchlist(e) {
    //   const formValues = { ...e.formValues };
    //   e.formValues.gridId = this.gridId || undefined;
    //   this.$refs.roleTable.getDataSource(e.formValues);
    // },
    searchlist(e) {
      const transformObject = (obj) => {
        return Object.entries(obj).reduce((acc, [key, value]) => {
          acc[key] = Array.isArray(value) ? value[value.length - 1] : value;
          return acc;
        }, {});
      };
      e.formValues = transformObject(e.formValues);
      e.formValues.gridId = this.gridId || undefined;
      e.formValues.coreLeft = this.coreLeft || undefined;
      e.formValues.coreRight = this.coreRight || undefined;
      this.$refs.roleTable.getDataSource(e.formValues);
    },
    reset(e) {
      this.cuurentAreaCode = "";
      this.cuurentAreaCode1 = [];
      if (this.$refs.cascader) {
        this.$refs.cascader.$refs.input.value = "";
      }
      this.gridId = ""; // 清空 gridId 参数
      this.coreLeft = undefined;
      this.coreRight = undefined;
    },
    // 表格多选触发回调
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {
    this.owningGrid();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
    <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
.grey {
  background-color: #e6e9ee;
  color: #2d3138;
  border: #e6e9ee;
  border-radius: 4px;
}
.green {
  background-color: #ebf9e6;
  color: #67c23a;
  border-radius: 4px;
  border: #ebf9e6;
}
.yellow {
  background-color: #fffbe6;
  color: #d48806;
  border-radius: 4px;
  border: #fffbe6;
}
</style>